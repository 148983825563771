import { useMutation } from '@apollo/client';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { CONTACT_REQUEST } from '@fingo/lib/graphql';
import { useSnackBars, useTextFieldInput } from '@fingo/lib/hooks';
import useEmailFromMailLog from '@fingo/lib/hooks/useEmailFromMailLog';
import { LoadingButton } from '@mui/lab';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';

const ContactRequest = ({
  callbackCellphone,
  callbackEmail,
  onCompleted,
  SecondButtonComponent,
  elevation,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const mailLogId = searchParams.get('mail_log_id') || '';
  const { addAlert } = useSnackBars();
  const phonePrefix = t('Phone prefix');
  const [cellphone, _setCellphone] = useTextFieldInput('');
  const [mail, _setMail,, rawSetMail] = useTextFieldInput('');
  const setCellphone = useCallback((event) => {
    _setCellphone(event);
    callbackCellphone(event);
  }, []);
  const setMail = useCallback((event) => {
    _setMail(event);
    callbackEmail(event);
  }, []);
  const mailLogEmail = useEmailFromMailLog();
  useEffect(() => {
    if (mailLogEmail.length) {
      rawSetMail(mailLogEmail);
    }
  }, [mailLogEmail]);
  const [contactRequest, { loading: requestingContact }] = useMutation(CONTACT_REQUEST, {
    variables: {
      phone: `${phonePrefix}${cellphone}`,
      email: mail,
      utmCampaign,
      encryptedMailLogId: mailLogId,
    },
    onCompleted: () => {
      addAlert({
        id: 'contact-request',
        message: 'Se ha registrado correctamente su contacto.',
      });
      onCompleted();
    },
    onError: () => {
      addAlert({
        id: 'contact-request-denied',
        message: 'No hemos podido guardar su contacto. Revise que su correo y teléfono estén correctos y reintente.',
      });
    },
  });
  return (
    <Paper
      elevation={elevation}
      width="100%"
      sx={{
        py: 4,
        px: 8,
      }}
    >
      <Grid
        container
        columnSpacing={{
          xs: 0,
          md: 2,
        }}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="cellphone-input">Celular</InputLabel>
            <ValidationTextFieldInput
              Component={FilledInput}
              id="cellphone-input"
              onChange={setCellphone}
              color="gray.A200"
              type="text"
              value={cellphone}
              disableUnderline
              fullWidth
              validationtype="phone"
              startAdornment={<InputAdornment position="start">+{phonePrefix}</InputAdornment>}
            />
            <FormHelperText id="mail-input">Ingresa tu número de celular</FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="mail-input">Correo</InputLabel>
            <ValidationTextFieldInput
              Component={FilledInput}
              id="mail-input"
              onChange={setMail}
              color="gray.A200"
              type="text"
              value={mail}
              disableUnderline
              fullWidth
              validationtype="email"
            />
            <FormHelperText id="mail-input">Ingresa tu correo</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <LoadingButton
            variant="contained"
            size="small"
            onClick={contactRequest}
            loading={requestingContact}
          >
            Enviar
          </LoadingButton>
          {SecondButtonComponent}
        </Grid>
      </Grid>
    </Paper>
  );
};

ContactRequest.propTypes = {
  callbackCellphone: PropTypes.func,
  callbackEmail: PropTypes.func,
  onCompleted: PropTypes.func,
  SecondButtonComponent: PropTypes.node,
  elevation: PropTypes.number,
};

ContactRequest.defaultProps = {
  callbackCellphone: null,
  callbackEmail: null,
  onCompleted: null,
  SecondButtonComponent: null,
  elevation: 4,
};

export default ContactRequest;
