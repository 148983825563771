import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '@fingo/lib/helpers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ShoppingCartSummaryItem = ({
  title,
  color,
  weight,
  cartType,
  money,
  value,
  icon,
}) => (
  <>
    <Grid item xs={8}>
      <Typography
        variant="h6"
        color={color}
        sx={{
          fontSize: { xs: 12, md: 14 },
          fontWeight: weight || 'normal',
        }}
      >
        {title}
      </Typography>
      {cartType === 'preoffer' && title === 'Monto a depositar' && (
        <Typography sx={{ mt: 0.9 }} color="#323232">
          En base a simulación a 30 dias
        </Typography>
      )}
    </Grid>
    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        variant="h6"
        color={color}
        sx={{
          fontSize: { xs: 12, md: 14 },
          fontWeight: weight || 'normal',
        }}
      >
        {money ? `${formatMoney(value, true)}` : value}
      </Typography>
      {icon && <Box sx={{ ml: 2 }}>{icon}</Box>}
    </Grid>
  </>
);

ShoppingCartSummaryItem.propTypes = {
  cartType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      amount: PropTypes.number,
    }),
    PropTypes.number,
  ]).isRequired,
  color: PropTypes.string,
  money: PropTypes.bool,
  icon: PropTypes.element,
  weight: PropTypes.string,
};

ShoppingCartSummaryItem.defaultProps = {
  color: null,
  money: false,
  icon: null,
  weight: null,
};

export default ShoppingCartSummaryItem;
