import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { shoppingCartShowQuery } from '@fingo/lib/apollo/cache/localQuery';
import { useQuery } from '@apollo/client';
import { shoppingCartShow } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import ShoppingCartSummaryItem from './ShoppingCartSummaryItem';
import ShoppingCartStepperNavigation from '../ShoppingCartStepperNaviagtion';

const ShoppingCartSummary = ({
  cartType,
  summaryItems,
  cleanFunction,
  loading,
  actionButton,
  warningAlert,
}) => {
  const isMobile = useIsMobile();

  const {
    data: { showShoppingCart },
  } = useQuery(shoppingCartShowQuery);

  const cleanShoppingCart = useCallback(() => {
    cleanFunction();
    shoppingCartShow(!showShoppingCart);
  }, [showShoppingCart]);

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={100} />;
  }

  return (
    <>
      <Grid container rowSpacing={isMobile ? 1 : 2} ml="26px !important">
        {summaryItems
          .filter(({ value }) => value != null)
          .map(({ title, ...props }) => (
            <ShoppingCartSummaryItem
              key={title}
              cartType={cartType}
              {...{ title, ...props }}
            />
          ))}
        {warningAlert && (
          <Grid item xs={12}>
            {warningAlert}
          </Grid>
        )}
      </Grid>
      <ShoppingCartStepperNavigation onBack={cleanShoppingCart}>
        {actionButton}
      </ShoppingCartStepperNavigation>
    </>
  );
};

ShoppingCartSummary.propTypes = {
  cartType: PropTypes.string.isRequired,
  summaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.shape({ amount: PropTypes.number }),
        PropTypes.number,
      ]),
      color: PropTypes.string,
      money: PropTypes.bool,
      icon: PropTypes.element,
    }),
  ).isRequired,
  cleanFunction: PropTypes.func.isRequired,
  actionButton: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  warningAlert: PropTypes.element.isRequired,
};

export default ShoppingCartSummary;
