import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BackSide, Flippy, FrontSide } from 'react-flippy';
import ContactRequest from './ContactRequest';
import { useSimulationAmounts } from '../../hooks';

const SimulationAmounts = ({
  client,
  debtor,
  amount,
  days,
  showRegisterButton,
  elevation,
}) => {
  const {
    isFlipped,
    toggleIsFlipped,
    cellphone,
    setCellphone,
    mail,
    setMail,
    summary,
  } = useSimulationAmounts(client, debtor, amount, days);

  return (
    <Box>
      <Flippy
        isFlipped={isFlipped}
        flipOnClick={false}
        style={{
          margin: 0,
          boxShadow: 'none',
          padding: 0,
        }}
      >
        <FrontSide style={{ padding: 0, boxShadow: 'none' }}>
          <Paper
            elevation={elevation}
            width="100%"
            sx={{
              pt: 4,
              px: {
                xs: 2,
                md: 8,
              },
              pb: 2,
            }}
          >
            <Grid container rowSpacing={2}>
              {summary.map(({ label, value, format }) => (
                <React.Fragment key={label}>
                  <Grid item xs={8}>{label}</Grid>
                  <Grid item xs={4}>{format ? format(value) : value}</Grid>
                </React.Fragment>
              ))}
              <Grid
                container
                item
                xs={12}
                mt={2}
                alignItems="center"
                justifyContent="center"
                columnSpacing={4}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleIsFlipped}
                    size="small"
                    id="contact-register-button"
                  >
                    Quiero que me contacten
                  </Button>
                </Grid>
                <Grid item display={showRegisterButton ? 'inherit' : 'none'}>
                  <MuiLink
                    component={Link}
                    to={(currentLocation) => ({
                      pathname: '/landing-register',
                      search: `${currentLocation.search}&email=${mail}&phoneNumber=${cellphone}`,
                    })}
                    color="primary"
                    variant="body2"
                    align="left"
                    rel="noopener noreferrer"
                    underline="hover"
                  >
                    Registrarme
                  </MuiLink>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </FrontSide>
        <BackSide style={{ padding: 0, boxShadow: 'none' }}>
          <ContactRequest
            elevation={elevation}
            SecondButtonComponent={(
              <Button
                disableRipple
                disableElevation
                disableFocusRipple
                disableTouchRipple
                variant="link"
                size="small"
                onClick={toggleIsFlipped}
                id="back-to-simulator-button"
              >
                Volver a la simulación
              </Button>
            )}
            callbackCellphone={setCellphone}
            callbackEmail={setMail}
            onCompleted={toggleIsFlipped}
          />
        </BackSide>
      </Flippy>
    </Box>
  );
};

SimulationAmounts.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.string.isRequired,
  }),
  debtor: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rut: PropTypes.string.isRequired,
  }),
  amount: PropTypes.number,
  days: PropTypes.number,
  showRegisterButton: PropTypes.bool,
  elevation: PropTypes.number,
};

SimulationAmounts.defaultProps = {
  client: null,
  debtor: null,
  amount: 10000000,
  days: 30,
  showRegisterButton: true,
  elevation: 4,
};

export default SimulationAmounts;
