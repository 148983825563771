import React, { useCallback, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MobileStepper from '@mui/material/MobileStepper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import TitleAndInformationTooltip from '@fingo/lib/components/titles/TitleAndTooltip';

const ShoppingCartStepper = ({ steps, loading }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const isMobile = useIsMobile();

  const prevStep = useCallback(
    () => setCurrentStep((prev) => Math.max(prev - 1, 0)),
    [],
  );

  const nextStep = useCallback(
    () => setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1)),
    [steps],
  );

  if (loading || steps.length === 0) {
    return <CircularProgress />;
  }

  const {
    id,
    title,
    tooltipTitle,
    component: StepComponent,
    props: stepComponentProps,
  } = steps[currentStep];

  return (
    <Stack
      sx={{
        boxShadow: 0,
        '&:before': {
          opacity: 0,
        },
        maxWidth: null,
        width: '100%',
        height: '100%',
        py: 2,
        px: 2.5,
      }}
    >
      <MobileStepper
        steps={steps.length}
        activeStep={currentStep}
        position="static"
        variant="dots"
        sx={{ justifyContent: 'center' }}
      />
      <Stack flexGrow={1} spacing={2} mt={0.5}>
        <TitleAndInformationTooltip
          title={title}
          tooltipTitle={tooltipTitle}
          variant={isMobile ? 'h5' : 'h4'}
          align="center"
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
        <StepComponent
          key={id}
          prevStep={prevStep}
          nextStep={nextStep}
          {...stepComponentProps}
        />
      </Stack>
    </Stack>
  );
};

ShoppingCartStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      component: PropTypes.elementType.isRequired,
      tooltipTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      // eslint-disable-next-line react/forbid-prop-types
      props: PropTypes.object,
    }),
  ).isRequired,
  loading: PropTypes.bool,
};

ShoppingCartStepper.defaultProps = {
  loading: false,
};

export default ShoppingCartStepper;
