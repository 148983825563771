import React from 'react';
import PropTypes from 'prop-types';
import { useIsMobile } from '@fingo/lib/hooks';
import Stack from '@mui/material/Stack';
import { PreofferShoppingCart } from './PreofferShoppingCart';
import OfferShoppingCart from './OfferShoppingCart/OfferShoppingCart';
import { OrderingShoppingCart } from './OrderingShoppingCart';

const ShoppingCartStack = ({ toggleMenu, cartType }) => {
  const isMobile = useIsMobile();
  return (
    <Stack
      width={520}
      spacing={isMobile ? 1 : 4}
      alignItems="center"
      sx={{
        px: { xs: 0, md: 2 },
        py: 2,
        maxWidth: { xs: '100%', md: 500 },
        height: 500,
      }}
    >
      {cartType === 'available-factoring' && (
        <PreofferShoppingCart toggleMenu={toggleMenu} />
      )}
      {cartType === 'available-offers' && (
        <OfferShoppingCart toggleMenu={toggleMenu} />
      )}
      {cartType === 'ordering' && (
        <OrderingShoppingCart toggleMenu={toggleMenu} />
      )}
    </Stack>
  );
};

ShoppingCartStack.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  cartType: PropTypes.string,
};

ShoppingCartStack.defaultProps = {
  cartType: 'available-factoring',
};

export default ShoppingCartStack;
