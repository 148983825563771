import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { cleanPreOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';

const ShoppingUploadDocumentsFooter = ({ nextStep, setDocuments, loading }) => (
  <Grid container justifyContent="center" alignItems="center" paddingTop={2}>
    <Stack direction="row" alignItems="end">
      <Button variant="link" color="primary" size="small" onClick={() => { nextStep(); setDocuments({}); cleanPreOfferShoppingCart(); }} sx={{ textDecoration: 'underline' }} id="cart-preoffer-skip-documents">
        Omitir este paso
      </Button>
      <LoadingButton
        variant="contained"
        color="primary"
        size="small"
        loading={loading}
        onClick={() => { nextStep(); cleanPreOfferShoppingCart(); }}
        id="cart-preoffer-confirm"
      >
        Avanzar
      </LoadingButton>
    </Stack>
  </Grid>
);

ShoppingUploadDocumentsFooter.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ShoppingUploadDocumentsFooter;
