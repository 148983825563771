import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Download from '@mui/icons-material/Download';
import DoneIcon from '@mui/icons-material/Done';
import { useSelectedCompany } from '@fingo/lib/hooks';

const ShoppingFileUploadInput = ({ invoiceId, label, documents, setDocuments,
  uploadedDocuments }) => {
  const [file, setFile] = useState(null);
  const openUrl = (url) => window.open(url);
  const company = useSelectedCompany();

  const hasInvoiceProvider = company.invoiceProviderCredentials.at(-1)?.credentialProvider;
  const uploadInputId = label === 'PURCHASE_ORDER' ? `oc_${invoiceId}` : `invoice_${invoiceId}`;
  const documentName = documents[uploadInputId]?.file.name || null;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const addDocumentHandler = () => {
    const newDocument = {
      documentType: label,
      file,
    };
    setDocuments(() => ({
      ...documents,
      [uploadInputId]: newDocument,
    }));
  };

  useEffect(() => {
    if (file) {
      addDocumentHandler();
    }
  }, [file]);

  const hasInvoiceProviderCredentialsTooltipTitle = (
    <Typography variant="body2">
      ¡Tu PDF ya está cargado!{' '}
    </Typography>
  );

  return (
    <React.Fragment key={uploadInputId}>
      {hasInvoiceProvider && label === 'PDF'
        ? (
          <Tooltip
            title={hasInvoiceProviderCredentialsTooltipTitle}
            size="small"
            placement="right"
            arrow
          >
            <Box>
              <Chip
                size="small"
                label="Pdf de la factura"
                color="primary"
                variant="filled"
                onDelete={() => {}}
                deleteIcon={<DoneIcon />}
                disabled
                sx={{ marginInline: 1, marginTop: 0.5 }}
              />
            </Box>
          </Tooltip>
        ) : (
          <>
            {uploadedDocuments ? (
              <Chip
                id={uploadedDocuments.id}
                size="small"
                label={uploadedDocuments.type.name}
                color="primary"
                variant="filled"
                onClick={() => openUrl(uploadedDocuments.url)}
                onDelete={() => openUrl(uploadedDocuments.url)}
                deleteIcon={<Download />}
              />
            ) : (
              <Box display="flex" alignItems="center" sx={{ border: '1px dashed', borderRadius: '10px' }}>
                <Button
                  component="label"
                  htmlFor={uploadInputId}
                  variant="text"
                  size="small"
                  color="primary"
                  startIcon={<FileUploadIcon />}
                  sx={{ minWidth: 0, pr: 0, pl: 1 }}
                  flex={1}
                >
                  <Input
                    style={{ display: 'none' }}
                    id={uploadInputId}
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
                {documentName && (
                <Typography variant="body1" noWrap flex={4}>
                  {documentName}
                </Typography>
                )}
              </Box>
            )}
          </>
        )}
    </React.Fragment>
  );
};

ShoppingFileUploadInput.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  documents: PropTypes.objectOf(
    PropTypes.shape({
      documentType: PropTypes.string.isRequired,
      file: PropTypes.instanceOf(window.File).isRequired,
    }),
  ).isRequired,
  setDocuments: PropTypes.func.isRequired,
  uploadedDocuments: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.shape({
      code: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shortName: PropTypes.string.isRequired,
      __typename: PropTypes.string.isRequired,
    }),
    url: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
  }),
};

ShoppingFileUploadInput.defaultProps = {
  uploadedDocuments: null,
};

export default ShoppingFileUploadInput;
