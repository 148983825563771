import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import Stack from '@mui/material/Stack';
import { keyframes } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import Mail from '@mui/icons-material/Mail';
import useLocalStorageTimer from '@fingo/lib/hooks/useLocalStorageTimer';
import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import useGetUser from '@fingo/lib/hooks/useGetUser';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import dayjs from '@fingo/lib/config/dayjs';
import TransitionLeft from '@fingo/lib/components/animations/TransitionLeft';
import { SEND_EMAIL_VERIFICATION_MAIL } from '../../graphql/mutations';

const rotation = 1;

const myKeyframe = keyframes`
    0 %  { transform: translate(1px, 1px)   rotate(0deg)    },
    50%  { transform: translate(-1px, 1px)  rotate(-${rotation}deg);  },
`;

const MailVerificationSnackbar = () => {
  const {
    lastTimeStored: lastMailVerificationShowed,
    resetTimer: resetMailVerificationShowed,
  } = useLocalStorageTimer('MAIL_VERIFICATION');
  const [success, setSuccess] = useBooleanState();
  const currentUser = useGetUser();
  const selectedCompany = useSelectedCompany();

  const openSnackbar = useMemo(
    () => dayjs().diff(lastMailVerificationShowed, 'minutes') > 60
      && currentUser
      && !currentUser.isEmailVerified
      && selectedCompany?.hasLendingAcceptedterms,
    [lastMailVerificationShowed],
  );

  const [sendEmailVerificationMail] = useMutation(
    SEND_EMAIL_VERIFICATION_MAIL,
    {
      onCompleted: () => {
        setSuccess();
        resetMailVerificationShowed();
      },
    },
  );

  return (
    <>
      <Snackbar
        open={openSnackbar}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          animation: `${myKeyframe} 1s infinite ease`,
        }}
      >
        <SnackbarContent
          sx={{ mt: 4 }}
          message={(
            <Stack direction="row" alignItems="center">
              <Mail sx={{ color: 'white' }} />
              <Button
                size="small"
                onClick={sendEmailVerificationMail}
                variant="link"
                sx={{ color: 'white' }}
              >
                Verifica tu dirección de correo electrónico
              </Button>
              <IconButton
                sx={{ color: 'white' }}
                onClick={resetMailVerificationShowed}
              >
                <Close />
              </IconButton>
            </Stack>
          )}
        />
      </Snackbar>
      <Snackbar
        open={success}
        TransitionComponent={TransitionLeft}
        onClose={setSuccess}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert
          severity="success"
          sx={{ width: '100%', mt: 4 }}
          onClose={setSuccess}
        >
          Correo de validación envíado con éxito!
        </Alert>
      </Snackbar>
    </>
  );
};

export default MailVerificationSnackbar;
